.component {
  --height: 100lvh; /* Andere, duidelijkere benaming */

  position: relative;
  z-index: 0;

  & > * {
    min-height: var(--height);
  }
}

.componentPrimaryLayer {
  background-color: var(--color-black);
  color: var(--color-white);
  display: grid;
  position: relative;
  overflow: hidden;
  z-index: 0;

  & > .layoutPrimaryLayer {
    grid-area: 1 / -1;
  }

  & > .coverImage {
    width: 100%;
    height: 100%;
    grid-area: 1 / -1;
    z-index: -1;
  }

  & > .videoLayout {
    width: 100%;
    height: 100%;
    grid-area: 1 / -1;
    z-index: -1;
  }
}

.coverImage,
.videoLayout {
  position: relative;
  z-index: 0;

  & > .imageLayout {
    width: 100%;
    height: 100dvh;
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.4);
    pointer-events: none;
  }
}

.componentContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: var(--size-50) var(--size-20);

  @media (--viewport-md) {
    justify-content: center;
  }

  & > .layoutContent {
    width: 100%;
    max-width: var(--container-xl);
  }
}

.layoutPrimaryLayer,
.layoutSecondaryLayer {
  & > * {
    height: var(--height);
  }
}

.layoutContent {
  & > .title {
    margin-bottom: var(--size-50);
  }
}

.title {
  font-size: var(--font-size-70-200);
  font-family: var(--font-family-condensed);
  font-weight: var(--font-weight-condensed-800);
  line-height: var(--line-height-compact);
  letter-spacing: var(--letter-spacing-compact);
  text-transform: uppercase;
  padding: unset !important;
  hyphens: auto !important;

  @media (--viewport-md) {
    hyphens: none !important;
    letter-spacing: var(--letter-spacing-compact-xl);
  }
}

.buttons {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--size-15);

  @media (--viewport-md) {
    flex-direction: row;
    gap: var(--size-40);
  }

  & > * {
    width: 100%;

    @media (--viewport-md) {
      width: auto;
    }
  }
}
